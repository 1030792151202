
import { reactive, onMounted, computed, ref } from 'vue';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Actions } from "@/store/enums/LotEnums.ts";
import { Actions as ReportActions } from "@/store/enums/ReportsEnums.ts";
import { useStore } from 'vuex';
export default {
  name: 'Invoice',
  setup(){

    const store = useStore();

    const invoiceNumber = ref(null);

    const submitButton = ref<HTMLElement | null>(null);

    const errors = reactive({
      value : {}
    });

    const runReport = (e) => {

      if(submitButton.value){
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      store.dispatch(ReportActions.INVOICE,{
          invoiceNumber : invoiceNumber.value
      }).then((response) => {

        errors.value = [];
        submitButton.value?.removeAttribute("data-kt-indicator");

        const blob = new Blob([response], { type: 'application/pdf', filename : 'labRequestForm.pdf' } as object)

        const url = window.URL.createObjectURL(blob)

        window.open(url)

      }).catch((response) => {

        errors.value = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(response.data) as any)).errors;
        console.log(errors.value)
        submitButton.value?.removeAttribute("data-kt-indicator")

      })
    }

    onMounted(() => {

      store.dispatch(ReportActions.GET_ALL_INVOICES);

      setCurrentPageBreadcrumbs("Reports",[
        {title : "Invoice",to : "/reports/invoice"}
      ]);
    });

    return {
      store,
      invoiceNumber,
      runReport,
      submitButton,
      errors
    }
  }
}
