<template>
  <div>

    <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">

      <div class="card-header cursor-pointer">

        <div class="card-title m-0">

          <h3 class="fw-bolder m-0">Invoice</h3>

        </div>

      </div>
      <div class="card-body p-7">

        <div class="mb-3">

          <label class="col-form-label fw-bold fs-6">Invoice Number</label>

          <select class="form-select" :class="{'is-invalid' : errors.value && errors.value.invoiceNumber}" v-model="invoiceNumber">

            <option value="null" selected disabled>Select Invoice Number</option>

            <option :value="item.invoiceNumber" v-for="(item,key) in store.getters.allInvoiceNumbers" :key="key">{{item.invoiceNumber}}</option>

          </select>
          <span class="text-danger" v-if="errors.value && errors.value.invoiceNumber">{{ errors.value.invoiceNumber[0] }}</span>
        </div>
        <div class="row">

          <div class="col-12">

            <button
              type="submit"
              ref="submitButton"
              class="btn btn-lg btn-primary fw-bolder ms-auto d-block my-2"
              @click="runReport"
            >
              <span class="indicator-label">
                Run Report
              </span>

              <span class="indicator-progress">
                Please wait...
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>

          </div>

        </div>

      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { reactive, onMounted, computed, ref } from 'vue';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Actions } from "@/store/enums/LotEnums.ts";
import { Actions as ReportActions } from "@/store/enums/ReportsEnums.ts";
import { useStore } from 'vuex';
export default {
  name: 'Invoice',
  setup(){

    const store = useStore();

    const invoiceNumber = ref(null);

    const submitButton = ref<HTMLElement | null>(null);

    const errors = reactive({
      value : {}
    });

    const runReport = (e) => {

      if(submitButton.value){
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      store.dispatch(ReportActions.INVOICE,{
          invoiceNumber : invoiceNumber.value
      }).then((response) => {

        errors.value = [];
        submitButton.value?.removeAttribute("data-kt-indicator");

        const blob = new Blob([response], { type: 'application/pdf', filename : 'labRequestForm.pdf' } as object)

        const url = window.URL.createObjectURL(blob)

        window.open(url)

      }).catch((response) => {

        errors.value = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(response.data) as any)).errors;
        console.log(errors.value)
        submitButton.value?.removeAttribute("data-kt-indicator")

      })
    }

    onMounted(() => {

      store.dispatch(ReportActions.GET_ALL_INVOICES);

      setCurrentPageBreadcrumbs("Reports",[
        {title : "Invoice",to : "/reports/invoice"}
      ]);
    });

    return {
      store,
      invoiceNumber,
      runReport,
      submitButton,
      errors
    }
  }
}
</script>
